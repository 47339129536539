import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  useGetBalanceQuery,
  useBalanceSubscription,
} from "../generated/graphql";
import { useStationAuth } from "./StationAuthContext";

// ----------------------------------------------------------------------
interface BalanceContextType {
  balance: {
    entry: string | undefined;
    winnings: string | undefined;
    candy: string | undefined;
    cashout: string | undefined;
  };
}
const BalanceContext = createContext<BalanceContextType | undefined>(undefined);

type BalanceProviderProps = {
  children: ReactNode;
};

let maxSubscriptionConnectionRetries = 0;

export const BalanceProvider = ({ children }: BalanceProviderProps) => {
  const { playerId } = useStationAuth();

  const [
    { data: getBalanceSubscriptionResults, error: getBalanceSubscriptionError },
    startBalanceSubscription,
  ] = useBalanceSubscription({
    variables: {
      playerId: playerId!,
    },
    pause: playerId == null,
  });

  useEffect(() => {
    if (getBalanceSubscriptionError) {
      console.error(getBalanceSubscriptionError);
    }
  }, [getBalanceSubscriptionError]);

  useEffect(() => {
    // If the balance is undefined and the playerId is defined, then we will try to reconnect to the subscription
    if (
      getBalanceSubscriptionResults?.balance === undefined &&
      playerId &&
      maxSubscriptionConnectionRetries > 3
    ) {
      maxSubscriptionConnectionRetries++;
      startBalanceSubscription();
    }

    // If the balance is defined, then we will stop trying to reconnect to the subscription, and set the maxSubscriptionConnectionRetries to 0
    else if (getBalanceSubscriptionResults?.balance !== undefined) {
      maxSubscriptionConnectionRetries = 0;
    }
  }, [getBalanceSubscriptionResults, getBalanceSubscriptionError]);

  const balance = getBalanceSubscriptionResults?.balance;

  return (
    <BalanceContext.Provider
      value={{
        balance: {
          entry: balance?.entry,
          winnings: balance?.winnings,
          candy: balance?.candy,
          cashout: balance?.cashout,
        },
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export const useGetBalance = () => {
  const context = useContext(BalanceContext);
  if (context === undefined) {
    throw new Error(
      "useGetBalance must be used within an BalanceContextProvider"
    );
  }
  return context;
};
// ----------------------------------------------------------------------
