import { useEffect, useState } from "react";
import { useStationAuth } from "../../contexts/StationAuthContext";
import { useGetUserPetsQuery } from "../../generated/graphql";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import Iconify from "../Iconify";
import PetImage from "./PetImage";

export default function PetSelector({ selectedPet, setSelectedPet }: any) {
  const { playerId } = useStationAuth();
  const [firstLoad, setFirstLoad] = useState(false);
  const [showAddPet, setShowAddPet] = useState(false);
  const [{ data: userPetsData }] = useGetUserPetsQuery({
    variables: { playerId: playerId || "" },
  });
  const userPets = userPetsData?.getUserPets;

  useEffect(() => {
    if (!firstLoad && userPets) {
      setFirstLoad(false);
      setSelectedPet(userPets[0]);
    }
  }, [userPets]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {userPets?.map((pet) => {
        return (
          <Button
            key={pet.id}
            onClick={() => setSelectedPet(pet)}
            sx={{
              position: "relative",
              width: "60px",
              height: "60px",
              m: 1,
              borderRadius: "50%",
              overflow: "hidden",
              opacity: selectedPet?.id === pet?.id ? 1 : 0.5,
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "#5375c3",
                transform: "scale(1.1)",
              },
            }}
          >
            <PetImage pet={pet} height={"50px"} width={"50px"} />
            <img
              src={"/images/sidebar/pod.png"}
              alt={"pod"}
              height="70px"
              width="70px"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Button>
        );
      })}
      <Button
        onClick={() => setShowAddPet(true)}
        sx={{
          position: "relative",
          width: "60px",
          height: "60px",
          m: 1,
          borderRadius: "50%",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: "#5375c3",
            transform: "scale(1.1)",
          },
        }}
      >
        <Typography
          sx={{ fontSize: "25px", fontWeight: "bold", color: "#FFF", mb: 1.5 }}
        >
          +
        </Typography>
        <img
          src={"/images/sidebar/pod.png"}
          alt={"pod"}
          height="70px"
          width="70px"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </Button>

      <Dialog
        onClose={() => setShowAddPet(false)}
        open={showAddPet}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src="/logo.png" alt="egg" width="300px" />
          <IconButton
            aria-label="close"
            onClick={() => setShowAddPet(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minWidth: "375px",
            overflow: "auto",
          }}
        >
          <Typography variant="h3" sx={{ mb: 2 }}>
            Coming Soon!
          </Typography>
          <Typography>
            Soon you will be able to purchase additional pets to your account!
          </Typography>
          <img
            src="/images/sidebar/pets/1_4.png"
            alt="Alcade"
            style={{
              width: "100%",
              height: "100%",
              marginTop: 20,
              maxWidth: 125,
              borderRadius: 10,
              marginBottom: 20,
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
