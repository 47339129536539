import { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useStationAuth } from "../../contexts/StationAuthContext";
import { useGetBalance } from "../../contexts/BalanceContext";
import pets from "./pets.json";
import Incubator from "./Incubator";
import HowItWorks from "./HowItWorks";
import Iconify from "../Iconify";

import {
  useGetUserPetsQuery,
  useCanClaimPetQuery,
  useClaimPetMutation,
} from "../../generated/graphql";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

export default function PetView() {
  const { playerId } = useStationAuth();
  const { balance } = useGetBalance();
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  const [{ data: userPetsData }] = useGetUserPetsQuery({
    variables: { playerId: playerId || "" },
  });
  const userPets = userPetsData?.getUserPets;

  const [{ data: canClaimPetData }] = useCanClaimPetQuery({
    variables: { playerId: playerId || "" },
  });
  const canClaimPet = canClaimPetData?.canClaimPet;

  const showClaimPet = canClaimPet && userPets?.length === 0;
  const isInGame = useLocation().pathname.includes("/game");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* Top Bar - Candy */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            setShowHowItWorks(true);
          }}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            justifyItems: "center",
            background: "#4272E1",
            opacity: isInGame ? 0.5 : 1,
            borderRadius: "20px",
            padding: "15px",
            height: "50px",
            minWidth: "175px",
            position: "relative",
            mt: 2,
            mb: 1,
            ml: 5,
          }}
        >
          <img
            className="float"
            src="/images/sidebar/candy.png"
            alt="Candy"
            width="100px"
            style={{
              position: "absolute",
              top: -25,
              left: -20,
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "bold",
                color: "white",
                mx: 1,
                textAlign: "center",
              }}
            >
              {balance?.candy ? parseInt(balance?.candy).toLocaleString() : "0"}
            </Typography>
          </Box>
        </Button>
        {/* Info */}
        <IconButton
          onClick={() => {
            setShowHowItWorks(true);
          }}
          sx={{
            mt: 1,
            ml: 0.5,
          }}
        >
          <Iconify
            icon="mdi:information-outline"
            width={27}
            height={27}
            sx={{
              color: "white",
              opacity: isInGame ? 0.5 : 1,
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {showClaimPet ? <ClaimPet /> : <Incubator />}
      </Box>

      <Box>
        <HowItWorks
          showHowItWorks={showHowItWorks}
          setShowHowItWorks={setShowHowItWorks}
        />
      </Box>
    </Box>
  );
}

const ClaimPet = () => {
  const [loading, setLoading] = useState(false);
  const { playerId } = useStationAuth();
  const getRandomPetByType = (type: string) => {
    const petsOfType = pets.filter((pet) => pet.type === type);
    return petsOfType[Math.floor(Math.random() * petsOfType.length)];
  };

  // Get one random pet of each type
  const selectedPets = [
    getRandomPetByType("DOG"),
    getRandomPetByType("CAT"),
    getRandomPetByType("PIGEON"),
  ];

  const [{ data: claimPetData }, claimPet] = useClaimPetMutation();

  const [{ data: userPetsData }, getUserPets] = useGetUserPetsQuery({
    variables: { playerId: playerId || "" },
  });

  const onClickClaimPet = async (petId: number) => {
    try {
      setLoading(true);
      if (!playerId) throw new Error("Player ID is required");
      await claimPet({ playerId: playerId, petId: petId.toString() });
      await getUserPets({
        variables: { playerId: playerId },
      });
    } catch (error) {
      console.error("Error claiming pet:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 2 }}>
        Choose your pet!
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {selectedPets.map((pet) => (
          <Button
            key={pet?.id}
            disabled={loading}
            onClick={() => onClickClaimPet(parseInt(pet.id))}
            sx={{
              m: 1,
              mt: 0,
              height: "115px",
              width: "115px",
              borderRadius: "50%",
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
              background:
                "linear-gradient(225deg, rgba(150,187,255,1) 34%, rgba(87,127,167,1) 74%)",
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <img
              src={pet?.img + "_1.png"}
              alt={pet?.name}
              height="115px"
              width="115px"
            />
          </Button>
        ))}
      </Box>
    </Box>
  );
};
