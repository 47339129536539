import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import useCollapseDrawer from "../hooks/useCollapseDrawer";
import Sidebar from "../components/sidebar/Sidebar";

// ----------------------------------------------------------------------

const NAVBAR = {
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 102,
};

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,

  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick } = useCollapseDrawer();

  return (
    <Box
      sx={{
        display: { lg: "flex" },
        minHeight: { lg: 1 },
        background: "#000",
      }}
    >
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
      <Sidebar />
    </Box>
  );
}
