import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/pagination";

import { useStationAuth } from "../../contexts/StationAuthContext";
import { getVersion } from "@tauri-apps/api/app";
import { invoke } from "@tauri-apps/api";
import { useGetStationNameQuery } from "../../generated/graphql";

// ----------------------------------------------------------------------
const previewBackgroundImage = new Image();
previewBackgroundImage.loading = "eager";

export default function StationDetails() {
  const { isTauriApp } = useStationAuth();

  const [appVersion, setAppVersion] = useState("");
  const [stationId, setStationId] = useState("");
  const [stationName, setStationName] = useState("");

  const [{ data: stationData, error: stationDataError }] =
    useGetStationNameQuery({
      variables: {
        stationId: stationId,
      },
      pause: !stationId || stationId === "",
    });
  const _stationData = stationData?.getStationName;

  // Get app version and station ID
  useEffect(() => {
    if (isTauriApp) {
      getVersion().then((version) => {
        setAppVersion(version);
      });
      invoke("get_station_id").then((stationId) => {
        setStationId(stationId as string);
      });
      setStationName(_stationData || "");
    }
  });

  return (
    <>
      {/* Bottom right corner */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "9px",
            color: "white",
            fontWeight: "bold",
          }}
        >
          {appVersion && "v" + appVersion}
          {import.meta.env.VITE_APP_API.includes("staging") && " Staging"}
          {stationName && " - " + stationName}
          {stationId && " (" + stationId?.substring(0, 8) + "...)"}
        </Typography>
      </Box>
    </>
  );
}
