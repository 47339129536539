import pets from "./pets.json";

interface PetImageProps {
  pet: any;
  height: string;
  width: string;
}

export default function PetImage({ pet, height, width }: PetImageProps) {
  const petData = pets.find((p) => p.id === pet?.petId);
  const level = Math.min(pet?.level + 1, 4);
  const isMaxLevel = pet?.level + 1 === 5;
  const url = petData?.img + "_" + level + ".png";

  return (
    <img
      src={url}
      alt={pet?.name}
      height={height}
      width={width}
      className="float-pet"
      style={{
        position: "absolute",
        top: 0,
        left: 10,
        maxHeight: height,
        maxWidth: "auto",
        objectFit: "contain",
        filter: isMaxLevel ? "drop-shadow(0 0 10px gold)" : "none",
      }}
    />
  );
}
