import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import Iconify from "../Iconify";

export default function HowItWorks({
  showHowItWorks,
  setShowHowItWorks,
}: {
  showHowItWorks: boolean;
  setShowHowItWorks: (value: boolean) => void;
}) {
  return (
    <Dialog
      onClose={() => setShowHowItWorks(false)}
      open={showHowItWorks}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 4,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src="/logo.png" alt="egg" width="300px" />
        <IconButton
          aria-label="close"
          onClick={() => setShowHowItWorks(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ep:close-bold" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minWidth: "375px",
          overflow: "auto",
        }}
      >
        <Typography variant="h3" sx={{ mb: 2 }}>
          How it works
        </Typography>
        <Typography>
          Your Alcade account comes with a digital pet for you to care for! You
          will get rewarded when your pet reaches level 5. Use these candies to
          increase your pet XP bar and level them up!
        </Typography>
        <img
          src="/images/sidebar/info2.png"
          alt="Alcade"
          style={{
            width: "100%",
            height: "100%",
            marginTop: 20,
            maxWidth: 250,
            borderRadius: 10,
            marginBottom: 20,
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
          }}
        />

        <Typography>
          Candy are received when depositing funds into your account! Use these
          candies to increase your pets XP bar and level them up!
        </Typography>
        <img
          src="/images/sidebar/info1.png"
          alt="Alcade"
          style={{
            width: "100%",
            height: "100%",
            marginTop: 20,
            maxWidth: 250,
            borderRadius: 10,
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
